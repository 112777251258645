import React, {Component} from "react";
import './styles/NavBar.sass'
import {faDatabase} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SearchBar from "./SearchBar";
import Accordions from "./Accordions";
import Filters from "./Filters";
import cloneDeep from 'lodash/cloneDeep';
import UploadFile from "./UploadFile";

class NavBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            result: [],
            from: 0,
            size: 5,
            indices: this.props.indices,
            loading: false,
            filteredResult: null
        };
        this.getResults = this.getResults.bind(this)
        this.clearView = this.clearView.bind(this)
        this.filterResults = this.filterResults.bind(this)
    }

    getResults(index, id, q, from, size) {
        this.setState({
            loading: true
        })
        let esAddress = 'https://demo-outcite.gesis.org:443/';
            // http://outcite.gesis.org:80/
            // 'http://193.175.238.110:8003/'
        let url = id ? esAddress + index + '/_search?q=_id:' + id :
            q ? esAddress + index + '/_search?q=fulltext:"' + q + '" OR cermine_fulltext:"' + q + '" OR _id:"' + q + '"&from=' + from + '&size=' + size :
                esAddress + index + '/_search?from=' + from + '&size=' + size
        fetch(url)
            .then(response => response.json())
            .then(result => {
                if (result['hits']['hits'].length > 0) {
                    this.setState({
                        result: [...this.state.result, result],
                        loading: false
                    })
                }
                else{
                    this.setState({
                        loading: false
                    })
                }
            })
            .catch(error => console.log('error', error));
    }

    filterResults(filters) {
        if (filters.length > 0) {
            let resultCopy = cloneDeep(this.state.result);
            for (let s = 0; s < resultCopy.length; s++) {
                for (let i = 0; i < resultCopy[s]['hits']['hits'].length; i++) {
                    let _source = {}
                    for (const [key, value] of Object.entries(resultCopy[s]['hits']['hits'][i]['_source'])) {
                        for (let j = 0; j < filters.length; j++) {
                            if(Array.isArray(filters[j]['value'])){
                                filters[j]['value'].forEach((f)=>{
                                    if (f["value"] === key) {
                                        _source[key] = value
                                    }
                                })
                            }
                            else if (key === filters[j]['value']) {
                                _source[key] = value
                            }
                            // if (key === filters[j]['value']) {
                            //     _source[key] = value
                            // }
                        }
                    }
                    resultCopy[s]['hits']['hits'][i]['_source'] = _source

                }
                this.setState({filteredResult: resultCopy})
            }
        } else {
            this.setState({filteredResult: null})
        }
    }

    clearView() {
        this.setState({
            result: [],
            filteredResult: null
        })
        this.props.activate(false)
    }

    render() {
        // console.log(this.state.result)
        return <div className='row d-flex justify-content-center'>
            {/*<nav className="col-12 navbar navbar-expand-lg">*/}
                {/*<div className="row">*/}
                    {/*<div className="col-3 navbar-brand">*/}
                    {/*    /!*<FontAwesomeIcon className='nav-icon' icon={faDatabase}/>*!/*/}
                    {/*    /!*<span className='nav-icon'>USERS</span>*!/*/}
                    {/*</div>*/}
                    {/*<div className="col navbar-nav">*/}
                        {/*<div className="btn-group" role="group" aria-label="Basic outlined example">*/}
                            {/*{*/}
                            {/*    this.props.datasets ? this.props.datasets.map((item, ind) => (*/}
                            {/*        <button type="button" className="btn btn-outline-danger" disabled key={ind} onClick={() => {*/}
                            {/*            this.clearView()*/}
                            {/*            this.props.activate(true)*/}
                            {/*        }}>{item}</button>*/}
                            {/*    )) : null*/}
                            {/*}*/}
                        {/*</div>*/}
                    {/*</div>*/}
                    <SearchBar className="col"  placeholder={'Search id or query...'}
                               // class={'right'}
                               getResults={this.getResults}
                               clearView={this.clearView} index={this.state.indices} globalSearch/>

                {/*</div>*/}
            {/*</nav>*/}
            <div className='col-11 background-clr'>
                <UploadFile/>
            </div>
            {this.state.result.length > 0 ?
                this.state.result.map((item, ind) =>
                    <div className={ind === 0 ? "col-12 text-center filters-padding" : "none-display"} key={ind}>
                        <Filters filterResults={this.filterResults}/>
                    </div>
                )
                :
                null
            }
            {this.state.result.length > 0 && !this.state.filteredResult ?
                this.state.result.map((item, ind) =>
                    <div className="col-12" key={ind}>
                        <Accordions
                            dataset={'USERS'}
                            hits={item['hits']['hits']} updateResults={this.updateResults} from={this.state.from}
                            size={this.state.size} getIdIndex={this.props.getIdIndex}/>
                    </div>
                )
                : this.state.filteredResult ?
                    this.state.filteredResult.map((item, ind) => (
                        <div className="col-12" key={ind}>
                            <Accordions
                                dataset={'USERS'}
                                hits={item['hits']['hits']} updateResults={this.updateResults} from={this.state.from}
                                size={this.state.size} getIdIndex={this.props.getIdIndex}/>
                        </div>
                    ))
                    : this.state.loading ?
                        <div className="col-12 text-center load">
                            <div className="spinner-grow text-danger" role="status">
                                {/*<span className="sr-only">Loading...</span>*/}
                            </div>
                        </div>
                        :
                        null
            }
        </div>;

    }
}

export default NavBar;