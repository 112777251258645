import React, {Component} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import './App.css';
import Home from './components/Home';
import Header from './components/Header';
import Results from './components/Results';

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            _id: null,
            _index: null,
            _field: null

        };
        this.getIdIndex = this.getIdIndex.bind(this)
        // this.getRes = this.getRes.bind(this)
    }

    // getRes() {
    //     var myHeaders = new Headers();
    //     myHeaders.append("Content-Type", "application/json");
    //
    //     var raw = JSON.stringify({
    //         "from": 0,
    //         "size": 5,
    //         "query": {
    //             "bool": {
    //                 "must": {
    //                     "term": {
    //                         "has_fulltext": true
    //                     }
    //                 },
    //                 // "must_not": {
    //                 //     "exists": {
    //                 //         "field": "has_cermine_fulltext"
    //                 //     }
    //                 // }
    //             }
    //         }
    //     });
    //
    //     var requestOptions = {
    //         method: 'POST',
    //         headers: myHeaders,
    //         body: raw,
    //         redirect: 'follow'
    //     };
    //
    //     fetch("http://193.175.238.110:9200/outcite_ssoar/_search", requestOptions)
    //         .then(response => response.text())
    //         .then(result => console.log(result))
    //         .catch(error => console.log('error', error));
    //
    // }

    getIdIndex(id, index, field) {
        this.setState({
            _id: id,
            _index: index,
            _field: field
        })
        // if (field && field !== 'null') {
        //     window.open('http://localhost:3000/' + index + '/' + id + '/' + field, '_blank')
        // } else if (id && id !== 'null') {
        //     window.open('http://localhost:3000/' + index + '/' + id, '_blank')
        // } else {
        //     window.open('http://localhost:3000/', '_blank')
        // }
        if (field && field !== 'null') {
            window.open('https://demo-outcite.gesis.org:443/' + index + '/' + id + '/' + field, '_blank')
            // http://outcite.gesis.org:80/
            // http://193.175.238.110:8003/
        } else if (id && id !== 'null') {
            window.open('https://demo-outcite.gesis.org:443/' + index + '/' + id, '_blank')
        } else {
            window.open('https://demo-outcite.gesis.org:443/', '_blank')
        }
    }

    render() {
        // this.getRes()
        return (
            <div className="container">
                <BrowserRouter>
                    <div>
                        {/*<Header/>*/}
                        <Routes>
                            <Route path="/" element={<><Header/><Home getIdIndex={this.getIdIndex}/></>}/>
                            <Route path="/:index" element={<><Header showHomeBtn/><Results/></>}/>
                            <Route path="/:index/:id" element={<><Header showHomeBtn/><Results/></>}/>
                            <Route path="/:index/:id/:includes_field" element={<><Header showHomeBtn/><Results/></>}/>
                        </Routes>
                    </div>
                </BrowserRouter>
            </div>
        );
    }
}

export default App;
