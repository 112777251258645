import React, {Component} from "react";
import './styles/Header.sass'
import outcite from '../images/oucite.PNG'
import Button from "./Button";

class Header extends Component {
    render() {
        return (

            <div className="row border-btm">
                {this.props.showHomeBtn ?
                    <>
                        <div className="col-3 home-btn">
                            <a className='btn-clr' href='https://demo-outcite.gesis.org:443/'
                               rel='noreferrer'><Button iconName='Home' size='lg'/></a>

                        </div>
                        <div className="col-6 text-center"><img src={outcite} alt="outcite" onClick={()=>window.open("https://demo-outcite.gesis.org/", "_self")}/></div>
                    </>
                    :
                    <div className="col-12 text-center"><img src={outcite} alt="outcite" onClick={()=>window.open("https://demo-outcite.gesis.org/", "_self")}/></div>}
            </div>
        )
    }
}

export default Header;