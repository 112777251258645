import React, {Component} from "react";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Button extends Component {
    render() {
        return (
            this.props.iconName === "Home" ?
                <FontAwesomeIcon icon={faHome} size={this.props.size?this.props.size:'lg'}/>
                : null
        )
    }
}

export default Button;